<template>
    <div class="mt-3">
        <b-row>
            <h6 class="page-title pb-2 mt-3 text-uppercase">
                {{ $t("law-firms.title") }}
            </h6>
        </b-row>
        <b-row class="justify-content-end mb-3">
            <b-col class="text-end">
                <b-button v-if="allowCreateRA" class="main-btn" @click="goToAddLawFirm">
                    <b-icon-person-plus-fill scale="1.2"></b-icon-person-plus-fill>
                </b-button>
            </b-col>
            <b-col xl="3" md="6">
                <b-form-group class="mt-1">
                    <select class="form-select form-select-sm rounded-0" v-model="searchStatus" @change="refreshTable">
                        <option :value="null" disabled selected>Estado</option>
                        <option :value="null"></option>
                        <option value="0">Activo</option>
                        <option value="1">Inactivo</option>
                    </select>
                </b-form-group>
            </b-col>
            <b-col xl="3" md="6">
                <b-input-group class="mt-1">
                    <b-form-input placeholder="Buscar" class="rounded-0" size="sm" v-model="search"
                        @keydown.enter="filterTable"></b-form-input>
                    <b-input-group-append>
                        <b-button class="main-btn" size="sm" @click="filterTable">
                            <b-icon-search></b-icon-search>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card no-header no-body class="rounded-0 shadow-sm border-0">
                    <b-table ref="lawFirmtable" stacked="md" small hover select-mode="single" selectable show-empty
                        empty-text="No hay entidades que mostrar" :filter="filter" :busy.sync="isBusy" :fields="fields"
                        :items="retrieveData" :current-page="currentPage" :per-page="take" @row-clicked="rowClicked"
                        @filtered="onFiltered">
                        <template #cell(curStatus)="data">
                            {{ data.item.curStatus }}
                        </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mt-4 mb-5">
            <b-col>
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
                    class="my-0 justify-content-end">
                    <template #first-text>
                        <span><b-icon-chevron-double-left scale="1.3"></b-icon-chevron-double-left></span>
                    </template>
                    <template #prev-text>
                        <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
                    </template>
                    <template #next-text>
                        <span><b-icon-chevron-right scale="1.3"></b-icon-chevron-right></span>
                    </template>
                    <template #last-text>
                        <span><b-icon-chevron-double-right scale="1.3"></b-icon-chevron-double-right></span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";
import { mapGetters } from "vuex";

export default {
    name: "list-law-firms",
    data() {
        return {
            fields: [
                { key: "name", label: this.$t("general.name") },
                {
                    key: "creationDate",
                    label: this.$t("general.creation-date"),
                },
                { key: "lawFirmType", label: this.$t("general.law-firm-type") },
                { key: "cur", label: "CUR" },
                { key: "curStatus", label: this.$t("general.cur-status") },
            ],
            search: "",
            filter: null,
            isBusy: false,
            currentPage: 1,
            take: 10,
            rows: 1,
            searchStatus: null,
        };
    },
    computed: {
        ...mapGetters("application", ["allowSuperintendenceCreateRA"]),
        allowCreateRA() {
            return this.allowSuperintendenceCreateRA;
        },
    },
    methods: {
        filterTable() {
            this.currentPage = 1;
            this.filter = this.search;
        },
        onFiltered(filteredItems) {
            console.log(filteredItems);
        },
        async retrieveData(context) {
            this.isBusy = true;
            try {
                let payload = {
                    page: context.currentPage,
                    take: 10,
                    search: context.filter,
                    status: this.searchStatus
                };
                let response = await RepositoryFactory.lawFirm.getPaged(
                    payload
                );
                this.rows = response.data.total;
                let items = response.data.items;

                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    item.creationDate = this.parseAndConvertDate(
                        item.creationDate
                    );
                    item.curStatus = this.$t(`cur-status.${item.curStatus}`);
                    item.lawFirmType = this.$t(
                        `law-firm-types.${item.lawFirmType}`
                    );
                    item.name = item.name.toUpperCase()
                }

                return items;
            } catch (error) {
                window.console.log(error);
            }
            this.isBusy = false;
        },
        parseAndConvertDate(date) {
            return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
        },
        goToAddLawFirm() {
            this.$router.push({
                name: "add-law-firm",
            });
        },
        rowClicked(lawFirm) {
            this.$router.push({
                name: "law-firm",
                params: { id: lawFirm.id },
            });
        },
        refreshTable() {
            this.filterTable();
            this.$refs.lawFirmtable.refresh();
        },
    },
};
</script>