<template>
  <div class="mt-3">
    <b-row>
      <b-col>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
          <b-table ref="requestListTable" stacked="md" small hover select-mode="single" selectable show-empty
            empty-text="No hay registros que mostrar" :filter="filter" :busy.sync="isBusy" :fields="fields"
            :items="retrieveData" :current-page="currentPage" :per-page="take" @row-clicked="rowClicked"
            @filtered="onFiltered">
            <template #cell(type)="data">
              {{ $t(`criteria-type.${data.item.type}`) }}
            </template>
            <template #cell(createdAt)="data">
              {{ formatDate(data.item.createdAt) }}
            </template>
            <template #cell(status)="data">
              {{ $t(`review-request-status.${data.item.status}`) }}
            </template>
            <template #cell(action)="data">
              <div>
                <b-button size="sm" @click="reviewRequest(data.item)" class="main-btn">
                  <b-icon icon="eye" v-b-tooltip.hover title="Revisar" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-5">
      <b-col>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="take" size="sm"
          class="my-0 justify-content-end">
          <template #first-text>
            <span><b-icon-chevron-double-left scale="1.3"></b-icon-chevron-double-left></span>
          </template>
          <template #prev-text>
            <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
          </template>
          <template #next-text>
            <span><b-icon-chevron-right scale="1.3"></b-icon-chevron-right></span>
          </template>
          <template #last-text>
            <span><b-icon-chevron-double-right scale="1.3"></b-icon-chevron-double-right></span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import { RepositoryFactory } from "@/services/api";

export default {
  name: "request-list",
  props: {
    requestStatus: {
      type: Number,
      required: true,
    },
    criteriaStatus: {
      type: Number,
      required: true,
    },
    filter: {
      type: Object,
      required: false,
    },
    search: {
      type: String,
      required: false,
    },
    currentTab: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      fields: [
        { key: "lawFirm.name", label: "Abogado/Firma" },
        { key: "type", label: "Tipo" },
        { key: "createdAt", label: "Fecha" },
        { key: "lawFirm.cur", label: "CUR" },
        { key: "status", label: "Estado", class: "text-center" },
        { key: "action", label: "Acción", class: "text-center" },
      ],
      isBusy: false,
      currentPage: 1,
      take: 10,
      rows: 1,
    };
  },
  methods: {
    onFiltered(filteredItems) {
      console.log(filteredItems.length);
    },
    async retrieveData(context) {
      this.isBusy = true;
      try {
        let payload = {
          page: context.currentPage,
          take: this.take,
          filter: context.filter,
          search: this.search,
          requestStatus: this.requestStatus,
          criteriaStatus: this.criteriaStatus,
        };
        let response =
          await RepositoryFactory.lawFirm.getPagedRequestActivationInactivation(
            payload
          );
        this.rows = response.data.total;
        let items = response.data.items;

        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          item.lawFirm.name = item.lawFirm.name.toUpperCase()
        }

        return items;
      } catch (error) {
        window.console.log(error);
      }
      this.isBusy = false;
    },
    rowClicked(lawFirm) {
      console.log(lawFirm);
    },
    formatDate(date) {
      return moment.utc(date).local().format("DD/MM/YYYY hh:mm A");
    },
    reviewRequest(item) {
      this.$router.push({
        name: "review-request",
        params: { id: item.id, reviewTab: this.currentTab },
      });
    },
  },
};
</script>
