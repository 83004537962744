<template>
    <div class="mt-3 px-xl-5">
        <h6 class="page-title pb-2 text-uppercase">
            {{ $t("navbar.law-firms") }}
        </h6>
        <b-tabs fill nav-class="dash-nav-tab" v-model="tabIndex">
            <b-tab
                    :title="$t('law-firms.title')"
                    title-item-class="dash-tab"
                    @click="changeTab(0)"
            >
                <ListLawFirms />
            </b-tab>
            <b-tab
                    v-if="allowCurManagement"
                    :title="$t('law-firms-request-activation-inactivation.title')"
                    title-item-class="dash-tab"
                    @click="changeTab(1)"
            >
                <ManageRequestActivationInactivation v-if="tabIndex === 1"/>
            </b-tab>
            <b-tab
                    v-if="allowCriteria"
                    :title="$t('law-firms-criteria.title')"
                    title-item-class="dash-tab"
                    @click="changeTab(2)"
            >
                <CurManagement v-if="tabIndex === 2"/>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import ListLawFirms from "./ListLawFirms.vue";
import CurManagement from "../cur-management/CurManagement.vue";
import ManageRequestActivationInactivation from "../cur-management/ManageRequestActivationInactivation.vue";
import { mapGetters } from "vuex";

export default {
    name: "law-firms",
    components: {
        ListLawFirms,
        CurManagement,
        ManageRequestActivationInactivation,
    },
    created() {
        if (this.$route.params.tab) {
            this.tabIndex = this.$route.params.tab;
        }
    },
    data() {
        return {
            tabIndex: 0,
        };
    },
    computed: {
        ...mapGetters("application", [
            "allowSuperintendenceCurManagement",
            "allowSuperintendenceConfigureCriteria",
        ]),
        allowCurManagement() {
            return this.allowSuperintendenceCurManagement;
        },
        allowCriteria() {
            return this.allowSuperintendenceConfigureCriteria;
        },
    },
    methods: {
        changeTab(index) {
            this.tabIndex = index;
        },
    },
};
</script>
