<template>
  <div class="mt-3">
    <b-row>
      <h6 class="page-title pb-2 mt-3 text-uppercase">Listado de Criterios</h6>
    </b-row>
    <b-row class="justify-content-end mb-3">
      <b-col>
        <b-form-group>
          <label for="type" class="label-title">Agregar</label>
          <select
            v-model="posts.type"
            class="form-select rounded-0"
            id="type"
            @change="addModal"
          >
            <option
              v-for="option in criteriaType"
              :key="option.value"
              :value="option.value"
            >
              {{ $t(`criteria.${option.text}`) }}
            </option>
          </select>
        </b-form-group>
      </b-col>
      <b-col class="text-center">
        <b-card>
          <label class="label-title">Cantidad de tipos de activación</label>
          <div class="details-title-value">{{ counts.activation }}</div>
        </b-card>
      </b-col>
      <b-col class="text-center">
        <b-card>
          <label class="label-title">Cantidad de tipos de inactivación</label>
          <div class="details-title-value">{{ counts.inactivation }}</div>
        </b-card>
      </b-col>
      <b-col xl="3" md="6">
        <b-input-group>
          <b-form-input
            placeholder="Buscar"
            class="rounded-0"
            size="sm"
            v-model="search"
            @keydown.enter="filterTable"
          ></b-form-input>
          <b-input-group-append>
            <b-button class="main-btn" size="sm" @click="filterTable">
              <b-icon-search></b-icon-search>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-group class="mt-1">
          <select
            class="form-select form-select-sm rounded-0"
            v-model="searchType"
            @change="refreshTable"
          >
            <option :value="null" disabled selected>Tipo de criterio</option>
            <option :value="null"></option>
            <option
              v-for="option in criteriaType"
              :key="option.value"
              :value="option.value"
            >
              {{ $t(`criteria.${option.text}`) }}
            </option>
          </select>
        </b-form-group>
        <b-form-group class="mt-1">
          <select
            class="form-select form-select-sm rounded-0"
            v-model="searchState"
            @change="refreshTable"
          >
            <option :value="null" disabled selected>Estado</option>
            <option :value="null"></option>
            <option value="0">Activo</option>
            <option value="1">Inactivo</option>
          </select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card no-header no-body class="rounded-0 shadow-sm border-0">
          <b-table
            ref="rtable"
            stacked="md"
            small
            hover
            select-mode="single"
            selectable
            show-empty
            empty-text="No hay registros que mostrar"
            :filter="filter"
            :busy.sync="isBusy"
            :fields="fields"
            :items="retrieveData"
            :current-page="currentPage"
            :per-page="take"
            @filtered="onFiltered"
          >
            <template #cell(state)="data">
              <span
                v-b-tooltip.hover
                :title="
                  $t(`criteria.${getText(data.item.state, criteriaState)}`)
                "
                @click="openUpdate(data.item)"
              >
                <b-icon-toggle-on
                  scale="1.5"
                  style="color: green"
                  v-if="data.item.state === 0"
                />
                <b-icon-toggle-off scale="1.5" style="color: red" v-else />
              </span>
            </template>
            <template #cell(type)="data">
              {{ $t(`criteria.${getText(data.item.type, criteriaType)}`) }}
            </template>
            <template #cell(createdAt)="data">
              {{ formattedDate(data.item.createdAt) }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-5">
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="take"
          size="sm"
          class="my-0 justify-content-end"
        >
          <template #first-text>
            <span
              ><b-icon-chevron-double-left
                scale="1.3"
              ></b-icon-chevron-double-left
            ></span>
          </template>
          <template #prev-text>
            <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
          </template>
          <template #next-text>
            <span
              ><b-icon-chevron-right scale="1.3"></b-icon-chevron-right
            ></span>
          </template>
          <template #last-text>
            <span
              ><b-icon-chevron-double-right
                scale="1.3"
              ></b-icon-chevron-double-right
            ></span>
          </template>
        </b-pagination>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal
      v-model="showModal"
      content-class="rounded-0"
      dialog-class="long-modal"
      header-class="border-0"
      body-bg-variant="light"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="container">
        <b-row class="text-center p-3">
          <b-col>
            <p class="details-title">
              <strong
                >Esta a punto de <u>agregar</u> una nueva descripción de
                catálogo para una
                <u v-if="posts.type !== null">
                  {{ $t(`criteria.${getText(posts.type, criteriaType)}`) }}
                </u>
              </strong>
            </p>
            <p class="details-title">Revise su descripción antes de crearla.</p>
          </b-col>
        </b-row>
        <b-row class="p-3">
          <p class="label-title">
            Tipo de acción
            <span
              v-if="posts.type !== null"
              :class="`criteria-type-${posts.type}`"
              >{{ $t(`criteria.${getText(posts.type, criteriaType)}`) }}</span
            >
          </p>

          <b-form-group class="mt-2">
            <label for="criterion" class="label-title"
              >Introduce el criterio que deseas agregar:</label
            >
            <input
              v-model="posts.criterion"
              type="text"
              class="form-control rounded-0"
              id="criterion"
              required
            />
          </b-form-group>
        </b-row>

        <b-row class="text-center mt-2">
          <b-col>
            <b-overlay
              :show="sending"
              rounded
              opacity="0.7"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                class="main-btn me-3"
                size="sm"
                @click="save"
                :disabled="sending"
                >Confirmar</b-button
              >
            </b-overlay>
            <b-button size="sm" variant="secondary" @click="closeModal"
              >Cancelar</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- Modal -->
    <b-modal
      v-model="updateModal"
      content-class="rounded-0"
      dialog-class="long-modal"
      header-class="border-0"
      body-bg-variant="light"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="container">
        <b-row class="p-3">
          <p class="label-title">
            Tipo:
            <span v-if="posts.type !== null">
              {{ $t(`criteria.${getText(posts.type, criteriaType)}`) }}
            </span>
          </p>
          <p class="label-title">
            Estado:
            <span>
              {{ $t(`criteria.${getText(posts.state, criteriaState)}`) }}
            </span>
          </p>
          <p class="label-title">
            Motivo:
            <span>{{ posts.criterion }}</span>
          </p>
        </b-row>

        <b-row class="text-center mt-2">
          <b-col>
            <b-overlay
              :show="sending"
              rounded
              opacity="0.7"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                class="main-btn me-3"
                size="sm"
                @click="update"
                :disabled="sending"
              >
                <span v-if="posts.state === 0">Apagar</span>
                <span v-else>Encender</span>
              </b-button>
            </b-overlay>
            <b-button size="sm" variant="secondary" @click="closeUpdate"
              >Cancelar</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { criteriaState } from "@/config/criteria-state";
import { criteriaType } from "@/config/criteria-type";
import { RepositoryFactory } from "@/services/api";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "cur-management",
  data() {
    return {
      criteriaState: criteriaState,
      criteriaType: criteriaType,
      fields: [
        { key: "state", label: "Estado", class: "text-center" },
        { key: "type", label: "Tipo" },
        { key: "criterion", label: "Motivo" },
        { key: "createdAt", label: "Creado" },
        { key: "createSuperintendence", label: "Por" },
      ],
      searchType: null,
      searchState: null,
      search: "",
      filter: null,
      isBusy: false,
      currentPage: 1,
      take: 10,
      rows: 1,
      showModal: false,
      updateModal: false,
      posts: {
        state: 0,
        type: null,
        criterion: null,
      },
      sending: false,
      id: null,
      counts: {
        activation: 0,
        inactivation: 0,
      },
    };
  },
  computed: {
    ...mapGetters("application", ["allowSuperintendenceConfigureCriteria"]),
    allowCriteria() {
      return this.allowSuperintendenceConfigureCriteria;
    },
  },
  created() {
    this.getCount();
  },
  methods: {
    ...mapActions("modals", ["openModal", "setModalInfo"]),
    async getCount() {
      let response = await RepositoryFactory.criteriaRepository.getCount({
        search: this.search,
        type: this.searchType,
        state: this.searchState,
      });
      this.counts = response.data;
    },
    filterTable() {
      this.filter = this.search;
    },
    onFiltered(filteredItems) {
      console.log(filteredItems);
    },
    async retrieveData(context) {
      this.isBusy = true;
      try {
        let payload = {
          page: context.currentPage,
          take: 10,
          search: context.filter,
          type: this.searchType,
          state: this.searchState,
        };
        let response = await RepositoryFactory.criteriaRepository.getPaged(
          payload
        );
        this.rows = response.data.total;
        let items = response.data.items;
        return items;
      } catch (error) {
        window.console.log(error);
      }
      this.isBusy = false;
    },
    reset() {
      this.id = null;
      this.posts = {
        state: 0,
        type: null,
        criterion: null,
      };
    },
    addModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.reset();
    },
    async save() {
      if (this.posts.criterion === null || this.posts.criterion === "") {
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Debe escribir un criterio.",
        });
        return;
      }

      try {
        this.sending = true;
        var payload = {
          criteria: {
            state: parseInt(this.posts.state),
            type: parseInt(this.posts.type),
            criterion: this.posts.criterion,
          },
        };
        await RepositoryFactory.criteriaRepository.add(payload);
        this.refreshTable();
        this.closeModal();
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Confirmado.",
        });
      } catch (error) {
        window.console.log(error);
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Error al guardar motivo.",
        });
      } finally {
        this.sending = false;
      }
    },
    openUpdate(item) {
      this.id = item.id;
      this.posts = {
        state: item.state,
        type: item.type,
        criterion: item.criterion,
      };
      this.updateModal = true;
    },
    closeUpdate() {
      this.updateModal = false;
      this.reset();
    },
    async update() {
      try {
        this.sending = true;
        var state = this.posts.state === 0 ? 1 : 0;
        var payload = {
          id: this.id,
          criteria: {
            state: state,
            type: parseInt(this.posts.type),
            criterion: this.posts.criterion,
          },
        };
        await RepositoryFactory.criteriaRepository.update(payload);
        this.refreshTable();
        this.closeUpdate();
      } catch (error) {
        window.console.log(error);
        this.openModal();
        this.setModalInfo({
          title: "Aviso",
          message: "Error al modificar motivo.",
        });
      } finally {
        this.sending = false;
      }
    },
    refreshTable() {
      this.$refs.rtable.refresh();
      this.getCount();
    },
    formattedDate(date) {
      return moment.utc(date).local().format("DD/MM/YYYY hh:mm A");
    },
    getText(value, list) {
      const item = list.find((item) => item.value === value);
      return item ? item.text : null;
    },
  },
};
</script>

<style scoped>
.modal-custom-title {
  position: relative;
  width: 100%;
}

.close-modal-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.label-title {
  font-size: 14px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 3px;
}

.criteria-type-0 {
  text-transform: uppercase;
  color: green;
}
.criteria-type-1 {
  text-transform: uppercase;
  color: red;
}
</style>
