<template>
  <div class="mt-3">
    <b-row>
      <h6 class="page-title pb-2 mt-3 text-uppercase">
        {{ $t("law-firms-request-activation-inactivation.title") }}
      </h6>
    </b-row>
    <b-card border-variant="light" class="shadow-sm" header-class="border-0">
      <template #header>
        <h6 class="mb-0 details-title-value">Filtros de busqueda</h6>
      </template>
      <b-card-text>
        <b-row class="mt-2">
          <b-col md="6" lg="4">
            <label class="label-title">Fecha de inicio</label>
            <DatePicker v-model="filter.startDate" />
          </b-col>
          <b-col md="6" lg="4">
            <label class="label-title">Fecha final</label>
            <DatePicker v-model="filter.endDate" />
          </b-col>
          <b-col md="6" lg="4">
            <label class="label-title">Clasificación</label>
            <select v-model="filter.type" class="form-select rounded-0" @change="getCriterias">
              <option :value="null"></option>
              <option v-for="option in criteriaType" :key="option.value" :value="option.value">
                {{ $t(`criteria.${option.text}`) }}
              </option>
            </select>
          </b-col>
          <b-col md="12" lg="12" class="mt-2">
            <label class="label-title">Tipo de criterio (Según la clasificación seleccionada)</label>
            <div>
              <b-button class="w-100 collapse-criteria rounded-0" v-b-toggle.collapse-3>
                <b-row align-h="between">
                  <b-col class="text-start"> </b-col>
                  <b-col class="text-end">
                    <b-icon-chevron-down />
                  </b-col>
                </b-row>
              </b-button>
              <b-collapse invisible id="collapse-3">
                <b-card>
                  <b-form-checkbox-group class="checkbox-container" v-model="filter.criteria">
                    <b-row>
                      <b-col md="12" v-for="item in criterias" :key="item.key">
                        <b-form-checkbox :value="item.value">
                          <span class="checkbox-text">
                            {{ item.text }}
                          </span>
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </b-form-checkbox-group>
                </b-card>
              </b-collapse>
            </div>
            <!-- <b-form-select
              class="form-control rounded-0"
              v-model="filter.criteria"
              :options="criterias"
              multiple
            ></b-form-select> -->
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col class="text-end">
            <b-button size="sm" class="main-btn" @click="aplicarFiltro">Aplicar filtro</b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <b-row class="justify-content mt-3 mb-3">
      <b-col xl="3" md="6">
        <b-input-group class="mt-1">
          <b-form-input placeholder="Buscar" class="rounded-0" size="sm" v-model="search"
            @keydown.enter="filterTable"></b-form-input>
          <b-input-group-append>
            <b-button class="main-btn" size="sm" @click="filterTable">
              <b-icon-search></b-icon-search>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col>
        <b-input-group class="mt-1">
          <b-button class="main-btn me-3" size="sm" @click="download('csv')">
            Descargar CSV
          </b-button>
          <b-button class="main-btn me-3" size="sm" @click="download('excel')">
            Descargar Excel
          </b-button>
          <b-button class="main-btn" size="sm" @click="download('pdf')">
            Descargar PDF
          </b-button>
        </b-input-group>
      </b-col>
    </b-row>

    <div class="mt-4 mb-5">
      <b-tabs fill nav-class="dash-nav-tab" v-model="tabIndex">
        <b-tab title="Pendientes" title-item-class="dash-tab" @click="tabClickHandler(0)">
          <RequestList ref="requestList" :requestStatus="0" :criteriaStatus="0" :filter="applyFilter" :search="search"
            :currentTab="currentTab" v-if="currentTab === 0" />
        </b-tab>
        <b-tab title="Aprobados" title-item-class="dash-tab" @click="tabClickHandler(1)">
          <RequestList ref="requestList" :requestStatus="1" :criteriaStatus="1" :filter="applyFilter" :search="search"
            :currentTab="currentTab" v-if="currentTab === 1" />
        </b-tab>
        <b-tab title="Rechazados" title-item-class="dash-tab" @click="tabClickHandler(2)">
          <RequestList ref="requestList" :requestStatus="1" :criteriaStatus="2" :filter="applyFilter" :search="search"
            :currentTab="currentTab" v-if="currentTab === 2" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { criteriaType } from "@/config/criteria-type";
import RequestList from "./RequestList.vue";
import DatePicker from "@/components/widgets/DatePicker.vue";
import { RepositoryFactory } from "@/services/api";

export default {
  components: {
    RequestList,
    DatePicker,
  },
  created() {
    if (this.$route.params.reviewTab) {
      this.tabIndex = this.$route.params.reviewTab;
      this.tabClickHandler(this.tabIndex);
    }
  },
  data() {
    return {
      tabIndex: 0,
      currentTab: 0,
      criteriaType: criteriaType,
      filter: {
        startDate: null,
        endDate: null,
        type: null,
        criteria: [],
      },
      applyFilter: {
        startDate: null,
        endDate: null,
        type: null,
        criteria: [],
      },
      criterias: [],
      search: "",
    };
  },
  methods: {
    tabClickHandler(currentTab) {
      this.currentTab = currentTab;
    },
    aplicarFiltro() {
      this.applyFilter = Object.assign({}, this.filter);
    },
    filterTable() {
      this.$refs.requestList.$refs.requestListTable.refresh();
    },
    async getCriterias() {
      this.filter.criteria = [];
      this.criterias = [];
      if (this.filter.type != null) {
        try {
          this.loading = true;
          let response = await RepositoryFactory.criteriaRepository.getAll({
            type: this.filter.type,
          });
          response.data.forEach((item) => {
            this.criterias.push({ value: item.id, text: item.criterion });
          });
        } catch (error) {
          window.console.log(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async download(type) {
      try {
        this.loading = true;
        let payload = {
          filter: this.filter,
          search: this.search,
          requestStatus: this.$refs.requestList.requestStatus,
          criteriaStatus: this.$refs.requestList.criteriaStatus,
          contentType: type,
        };
        let response =
          await RepositoryFactory.lawFirm.getRequestActivationInactivations(
            payload
          );

        if (response != null) {
          var fileName = "Pendientes";
          if (this.currentTab === 1) {
            fileName = "Aprobados";
          }
          if (this.currentTab === 2) {
            fileName = "Rechazados";
          }

          var contentType = "";
          var ext = "";

          switch (type) {
            case "csv":
              contentType = "text/csv; charset=utf-8";
              ext = "csv";
              break;
            case "excel":
              contentType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              ext = "xlsx";
              break;
            case "pdf":
              contentType = "application/pdf";
              ext = "pdf";
              break;
          }

          this.downloadFile(response.data, `${fileName}.${ext}`, contentType);

          RepositoryFactory.event.add({
            code: 2004,
            details: `${fileName}.${ext}`
          });
        }
      } catch (error) {
        window.console.log(error);
      } finally {
        this.loading = false;
      }
    },
    downloadFile(data, filename, contenType) {
      const blob = new Blob([data], { type: contenType });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);

    },
  },
};
</script>

<style lang="scss">
.label-title {
  font-size: 14px;
  font-weight: 500;
  color: #717171;
  margin-bottom: 3px;
}

.collapse-criteria {
  background-color: white !important;
  color: black !important;
  border-color: #406892 !important;
}
</style>
